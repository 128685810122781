import {
  type ZodIssueOptionalMessage,
  type ErrorMapCtx,
  type ZodErrorMap,
  ZodIssueCode,
  ZodParsedType,
  defaultErrorMap,
  z,
} from 'zod'
import type {
  ComposerDateTimeFormatting,
  ComposerTranslation,
  VueI18n,
} from 'vue-i18n'
import type { } from '@nuxtjs/i18n'
import { joinValues, jsonStringifyReplacer } from './utils'

const zodDate = z.string().regex(/(\d{4})-\d{2}-(\d{2})/)

interface i18nOptions {
  [key: string]: unknown
}

const makeZodI18nMap
  = (i18n: VueI18n, key = 'errors'): ZodErrorMap =>
    (issue: ZodIssueOptionalMessage, ctx: ErrorMapCtx): { message: string } => {
      let message: string
      message = defaultErrorMap(issue, ctx).message

      let options = {} as i18nOptions

      const t = i18n.t as ComposerTranslation
      const te = i18n.te
      const d = i18n.d as ComposerDateTimeFormatting

      const translateLabel = (message: string, options: i18nOptions) => {
        return te(`${key}.${message}WithPath`)
          ? t(`${key}.${message}WithPath`, options)
          : t(`${key}.${message}`, options)
      }

      switch (issue.code) {
      case ZodIssueCode.invalid_type:
        if (issue.received === ZodParsedType.undefined) {
          message = 'invalidTypeReceivedUndefined'
        }
        else {
          message = 'invalidType'
          options = {
            expected: te(`types.${issue.expected}`) ? t(`types.${issue.expected}`) : issue.expected,
            received: te(`types.${issue.received}`) ? t(`types.${issue.received}`) : issue.received,
          }
        }
        break
      case ZodIssueCode.invalid_literal:
        message = 'invalidLiteral'
        options = {
          expected: JSON.stringify(
            issue.expected,
            jsonStringifyReplacer,
          ),
        }
        break
      case ZodIssueCode.unrecognized_keys:
        message = 'unrecognizedKeys'
        options = {
          keys: joinValues(issue.keys, ', '),
        }
        break
      case ZodIssueCode.invalid_union:
        message = 'invalidUnion'
        break
      case ZodIssueCode.invalid_union_discriminator:
        message = 'invalidUnionDiscriminator'
        options = {
          options: joinValues(issue.options),
        }
        break
      case ZodIssueCode.invalid_enum_value:
        message = 'invalidChoiceValue'
        options = {
          options: joinValues(issue.options),
          received: issue.received,
        }
        break
      case ZodIssueCode.invalid_arguments:
        message = 'invalidArguments'
        break
      case ZodIssueCode.invalid_return_type:
        message = 'invalidReturnType'
        break
      case ZodIssueCode.invalid_date:
        message = 'invalidDate'
        break
      case ZodIssueCode.invalid_string:
        if (typeof issue.validation === 'object') {
          if ('startsWith' in issue.validation) {
            message = 'invalidString.startsWith'
            options = {
              startsWith: issue.validation.startsWith,
            }
          }
          else if ('endsWith' in issue.validation) {
            message = 'invalidString.endsWith'
            options = {
              endsWith: issue.validation.endsWith,
            }
          }
        }
        else {
          message = `invalidString.${issue.validation}`
          options = {
            validation: t(`validations.${issue.validation}`),
          }
        }
        break
      case ZodIssueCode.too_small:
        message = `tooSmall.${issue.type}.${
          issue.exact
            ? 'exact'
            : issue.inclusive
              ? 'inclusive'
              : 'notInclusive'
        }`
        options = {
          minimum:
              issue.type === 'date'
                ? d(new Date(issue.minimum as string | number))
                : issue.minimum,
        }

        break
      case ZodIssueCode.too_big:
        message = `tooBig.${issue.type}.${
          issue.exact
            ? 'exact'
            : issue.inclusive
              ? 'inclusive'
              : 'notInclusive'
        }`
        options = {
          maximum:
              issue.type === 'date'
                ? d(new Date(issue.maximum as string | number))
                : issue.maximum,
        }
        break
      case ZodIssueCode.custom:
        message = 'custom'
        if (issue.params?.i18n) {
          message = issue.params.i18n
        }
        break
      case ZodIssueCode.invalid_intersection_types:
        message = 'invalidIntersectionTypes'
        break
      case ZodIssueCode.not_multiple_of:
        message = 'notMultipleOf'
        options = {
          multipleOf: issue.multipleOf,
        }
        break
      case ZodIssueCode.not_finite:
        message = 'notFinite'
        break
      }
      options.path = issue.path.join('.') || ''
      message = translateLabel(message, options)

      return { message }
    }

export { zodDate, makeZodI18nMap }
