<script setup lang="ts">
import { type Slot, useSlots, type VNode } from 'vue'
import { hasOwnProperty } from '~/src/utils'
import { ButtonSize, ButtonType } from '~/src/enums/Button'

interface Props {
  type?: ButtonType
  size?: ButtonSize
  loading?: boolean
  disabled?: boolean
  active?: boolean
  submit?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: ButtonType.Accent,
  size: ButtonSize.Big,
})

const slots = useSlots()
const isChildrenNodeEmpty = (slot: Slot) => {
  const vNode: VNode | undefined = slot()[0]
  const children = vNode?.children

  if (typeof children === 'string') {
    return children.trim() !== ''
  }
  return children
}
const hasDefault = computed(() => slots.default && isChildrenNodeEmpty(slots.default))

const hasIcon = hasOwnProperty(slots, 'icon')

const hasAppend = hasOwnProperty(slots, 'append')
</script>

<template>
  <button
    :disabled="props.disabled || props.loading"
    class="button h-fit relative text-button rounded border border-solid border-transparent grid auto-cols-max auto-rows-min items-center grid-flow-col"
    :class="{ [`button--type--${props.type}`]: true, [`button--size--${props.size}`]: true, 'button--single': !hasDefault, 'button--active': props.active }"
    :type="submit ? 'submit' : 'button'"
  >
    <div :class="{ 'hidden': !hasIcon, 'text-transparent': loading }">
      <slot name="icon" />
    </div>
    <div :class="['px-1', { 'hidden': !hasDefault, 'text-transparent': loading }]">
      <slot />
    </div>
    <div :class="{ 'hidden': !hasAppend, 'text-transparent': loading }">
      <slot name="append" />
    </div>
    <div
      v-if="loading"
      class="spinner"
    />
  </button>
</template>

<style lang="postcss" scoped>
.button--type--accent {
  @apply bg-button-gradient-accent text-white-100 border-dark-accent shadow-small-red;
  &.button--size--big {
    @apply p-2 gap-2;
  }
  &.button--size--small {
    @apply text-button-12 px-1 gap-1;
    &.button--single {
      @apply px-0;
    }
  }
  &:hover {
    @apply bg-none bg-dark-accent;
  }
  &:disabled {
    @apply bg-none border-transparent bg-bleached-accent shadow-transparent;
  }
}
.button--type--secondary {
  @apply bg-button-gradient-white text-black-100 border-black-10 shadow-small-gray;
  &.button--size--big {
    @apply p-2 gap-2;
    .button__default-slot {
      @apply px-1;
    }
  }
  &.button--size--small {
    @apply text-button-12 px-1 gap-1;
    &.button--single {
      @apply px-0;
    }
  }
  &:hover {
    @apply bg-none bg-black-5 border-black-20;
  }
  &:disabled {
    @apply bg-none border-black-10 bg-transparent shadow-transparent;
    & > * {
      @apply opacity-40;
    }
  }
}
.button--type--link {
  @apply transition duration-75;
  &.button--size--big {
    @apply p-2 gap-2;
    .button__default-slot {
      @apply px-1;
    }
  }
  &.button--size--small {
    @apply text-button-12 px-1 gap-1;
    &.button--single {
      @apply px-0;
    }
  }
  &:hover {
    @apply bg-black-5;
  }
  &:disabled {
    @apply bg-transparent;
    & > * {
      @apply opacity-40;
    }
  }
  &.button--active {
    @apply text-accent;
  }
}
.button--type--header {
  @apply transition duration-75 p-2 gap-1;
  &:hover {
    @apply bg-black-5;
  }
  &:disabled {
    @apply bg-transparent;
    & > * {
      @apply opacity-40;
    }
  }
  &.button--active {
    @apply bg-rose text-accent;
  }
}
</style>
